@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Aquire;
  src: url(../public/fonts/aquire-font/Aquire-BW0ox.otf);
}

* {
  margin: 0;
  padding: 0%;
  color: white;
  font-family: Aquire;
}
.three-scene-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  color: white; 
}
